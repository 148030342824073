import React from 'react'

import {
  Form,
  Grid,
  Segment
} from 'semantic-ui-react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomepageLayout from '../components/HomepageLayout'

const Contato = () => (
  <Segment style={{
      padding: '8em 0em'
    }} vertical="vertical">
    <Form action='http://jrmagon.com.br/util/handle_email.php' method='POST'>
      <Grid container="container" stackable="stackable" verticalAlign='middle' inverted>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input label='Nome' placeholder='seu nome' name='name' />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input label='E-Mail' placeholder='seu email' name='email' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label='Assunto' placeholder='qual o problema?' name='subject' />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.TextArea label='Mensagem' placeholder='Como podemos ajudar?' name='message'/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Button type='submit' color='yellow'>Enviar</Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Form.Input type='hidden' name='_next' value='https://www.izione.com.br/okcontato' />
      <Form.Input type='hidden' name='_to' value='contato@izione.com.br' />
    </Form>
  </Segment>
)

const ContatoPage = () => (
  <Layout>
    <SEO title="Contato" lang="pt" keywords={['software', 'tecnology', 'web', 'b2b', 'e-comerce']}  />
    <HomepageLayout showLogo={false} page='contato'>
      <Contato />
    </HomepageLayout>
  </Layout>
)

export default ContatoPage
